
import { SupportApiService } from "../../Service/SupportApiService";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import Accordion from 'react-bootstrap/Accordion';
import { useHistory, useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMore from '../../assets/images/icons/ExpandMore.svg';
import Swal from "sweetalert2";
import DeleteItemIcon from "../../assets/images/icons/DeleteItem.png";

import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CookieService } from "../../Service/CookieService";
import { OrderApiService } from "../../Service/OrderApiService";
import { ButtonGroup } from "react-bootstrap";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import { CommonService } from "../../Service/CommonService";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { VisibilityOutlined } from "@mui/icons-material";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import ViewProductDetail from "./ViewProductDetail";
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MessageApiService } from "../../Service/MessageApiService";
import TrashIcon from "../../assets/images/icons/Trash_Black.svg";
import "../../sass/responsive.scss";
import EditIcon from '../../assets/images/icons/EditIcon.svg';

const ViewSupportRequest: React.FC = () => {

    const {id} = useParams<{id: string}>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isSpinner, setSpinner] = useState(false);
    
    const [isOpenEditSection, setOpenEditSection] = useState(false);
    const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [unreadGroupsCount, setUnreadGroupsCount] = React.useState<number>(0);
    const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
    const [customerServiceRequest, setCustomerServiceRequest] = useState<any>([]);
    const [customerServiceRequestHistory, setCustomerServiceRequestHistory] = useState<any[]>([]);
    const [customerServiceRequestDocs, setCustomerServiceRequestDocs] = useState([]);
    
    const serviceRequestUpdateStatus = useRef({
        customerServiceRequestId: "",
        reason: "",
        status: "",
        spendHourOnRequest: ""
      });

    const [spendHour, setSpendHour]  = useState("");
    const [reason,setReason] = useState(""); 

    const [isCancelled, setIsCancelled] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [error, setError] = useState("");

    const handleClose = () => {
      setShow(false);
      setError("");
      setReason("");
      setSpendHour("");
    };


  const handleSpendHourChange = (e:any) => {
    setError("");
    setSpendHour(e.target.value);
  }

  const handleReasonChange = (e:any) => {
    setError("");
    setReason(e.target.value);
  }

  const deleteServiceRequest = async () => {
    Swal.fire({
      html: `
        <style>
          .custom-swal .icon-container {
              background-color: #ffebee;
              border-radius: 50%;
              width: 105px;
              height: 105px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto 20px;
            }
            .custom-swal .icon-container i {
              color: #ff3e4e;
              font-size: 24px;
            }
            .custom-swal h2 {
              font-size: 20px;
              margin-bottom: 10px;
              margin-top: 20px;
            }
            .custom-swal p {
              font-size: 14px;
              color: #666;
              margin-bottom: 20px;
            }
            .custom-swal .button-container {
              margin-top: 40px;
              display: flex;
              justify-content: center;
              gap: 20px;
            }
            .custom-swal button {
              border: none;
              padding: 10px 50px;
              border-radius: 30px;
              font-size: 14px;
              cursor: pointer;
            }
            .custom-swal .cancel-btn {
              background-color: #e0e0e0;
              color: #333;
            }
            .custom-swal .delete-btn {
              background-color: #ec2323;
              color: white;
            }
        </style>
        <div class="custom-swal">
          <div class="icon-container" id="iconContainer"></div>
          <h2>Are you sure?</h2>
          <p>Remove service request</p>
          <div class="button-container">
            <button class="cancel-btn" id="cancelBtn">Cancel</button>
            <button class="delete-btn" id="deleteBtn">Remove</button>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      focusCancel: true,
      customClass: {
        popup: "custom-popup",
      },
      backdrop: `rgba(0,0,0,0.4)`,
      width: "500px",
      background: "#f8f8f8",
      didOpen: () => {
        // Add the image after the popup has been rendered
        const iconContainer = Swal.getPopup().querySelector("#iconContainer");
        if (iconContainer) {
          const img = document.createElement("img");
          img.src = DeleteItemIcon;
          img.width = 47;
          img.height = 51;
          iconContainer.appendChild(img);
        }
  
        // Add event listeners to the buttons
        const cancelBtn = Swal.getPopup().querySelector("#cancelBtn");
        const deleteBtn = Swal.getPopup().querySelector("#deleteBtn");
  
        cancelBtn?.addEventListener("click", () => {
          Swal.close();
        });
  
        deleteBtn?.addEventListener("click", async () => {
          console.log("Delete button clicked"); // Debugging log
          try {
            const response = await SupportApiService.deleteCustomerServiceRequest(id);
            console.log("Service request deleted", response.data); // Debugging log
  
            // Check if the response indicates success
            if (response.data ) {
              setTimeout(() => {
                CommonService.Toast.fire({
                  title: "Service request removed successfully",
                  icon: "success",
                });
  
              }, 1000);
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
              history.push(`${linkPrefix}/customer-service`);
            } else {
              throw new Error("Failed to delete service request");
            }
          } catch (error) {
            console.error("Error deleting service request:", error);
          }
  
          Swal.close();
        });
      },
    });
  };
  

    const downloadCustomerServiceRequestDOC = async(item:any) => {
        //hare we need to call the api and download the doc

        const requestDto = {
            id: item.id,
            companyId: item.companyId,
            fileName: item.fileName,
          };
      
          try {
            const response: any = await SupportApiService.downloadCustomerServiceRequestDOC(
              requestDto
            );
            // Assuming response.data is the blob data
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = item.fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    }

    const fetchCustomerServiceRequestHistory = async () => {
        //fetch all the history for service request

        try {
            const response = await SupportApiService.fetchCustomerServiceRequestHistory(id);
            console.log(response?.data);
            setCustomerServiceRequestHistory(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }

    }

    const fetchCustomerServiceRequestDocs = async () => {
        //fetch all the docs service request

        try {
            const response = await SupportApiService.fetchCustomerServiceRequestDocs(id);
            console.log(response?.data);
            setCustomerServiceRequestDocs(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }
    }

    const getCustomerServiceRequestDetails = async () => {
        try {
            const response = await SupportApiService.getCustomerServiceRequest(id);
            console.log(response?.data);
            setCustomerServiceRequest(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }
    }

    

    function formatStatus(status?: string): string {
        let formattedStatus = "";
        if (status !== undefined) {
          formattedStatus = status
            ?.split(/[_\\]/)
            .map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        }
        return formattedStatus;
      }

     const handleEditServiceRequest = () => {
        const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
        history.push(`${linkPrefix}/edit-customer-service-request/${id}`);
     }
    
     const fetchUnreadMessageCount = async () => {
      try{
        const response = await SupportApiService.fetchUnreadMessageCount(id);
        setUnreadMessageCount(response.data);
        
      } catch(error) {
        console.log("error is occurred while api calling")
      }

     }

    //use effect
    useEffect(() => {
        fetchCustomerServiceRequestHistory();
        getCustomerServiceRequestDetails();
        fetchCustomerServiceRequestDocs();
        fetchUnreadMessageCount();
    },[]);

    const routeBack = () => {
        history.goBack();
      };

     //handle edit button click
  const handleEditButtonClick = () => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
  };

  //handle message box click
  const handleMessageBoxClick = () => {
    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setOpenEditSection(false);
      setMessageBoxOpen(true);
      setUnreadMessageCount(0)
    }
  };

  const handleAdminMessageClick = () => {
    setShowMessageBox((prev) => !prev);

    // Reset unread groups count when the message box is toggled
    setUnreadGroupsCount(
      unreadGroupsCount > 0 && unreadMessageCount > 0
        ? unreadGroupsCount - 1
        : unreadGroupsCount
    );
  };

  const fetchData = async () => {
    try {
        fetchCustomerServiceRequestHistory();
        getCustomerServiceRequestDetails();
        fetchCustomerServiceRequestDocs();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //data grid columns for docs
  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const fullFileName = row.fileName || "";
        const fileNameAfterFirstUnderscore = fullFileName.split('_').slice(1).join('_');
        return fileNameAfterFirstUnderscore || fullFileName;
      },
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "userName",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.userName}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadCustomerServiceRequestDOC(params.row)}
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };


  const handleStatusChange = async(newStatus:any) => {
    
    const status = newStatus?.replace(/ /g, "_").toUpperCase();

    const currentStatus = {
      customerServiceRequestId: id,
      serviceName: customerServiceRequest?.serviceName,
      reason: "",
      status: status,
      spendHourOnRequest: ""
    };

    serviceRequestUpdateStatus.current = currentStatus;


    if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
      setIsCancelled(true);
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
      setIsCancelled(false);
      handleShow();
    } else {
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeCustomerServiceRequestStatus();
    }

  }

  const changeCustomerServiceRequestStatus = async() => {
    if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED && reason.trim().length <= 0) {
      setError("Please enter cancellation reason");
      return;
    }

    else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED && spendHour.trim().length <= 0) {
      setError("Please enter spend time in hours");
      return;
    }


    
      //now call the api and update the status
    try {
      setIsLoading(true);
      //before calling the api we need to set the reason if status is cancelled and set spend hour if the status Closed
      if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
        serviceRequestUpdateStatus.current.reason = reason;
      }
      if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
        serviceRequestUpdateStatus.current.spendHourOnRequest = spendHour;
      }
      const response = await SupportApiService.updateCustomerServiceRequestStatus(serviceRequestUpdateStatus.current);
      getCustomerServiceRequestDetails();
      fetchCustomerServiceRequestHistory();
      handleClose();
      CommonService.Toast.fire({
        title: "Status updated successfully",
        icon: "success",
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error occurred while api calling");
      setIsLoading(false);
    }
  
  }

  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const handleRowClick = (index) => {
      // If clicking the already active row, close it
      setActiveRowIndex(activeRowIndex === index ? null : index);
  };

    return(
        <>
        <div className="usersList position-relative">
          <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex flex-wrap justify-content-between gap-4">
            {CommonService.isAdmin() && (
              <div className="d-flex align-items-center gap-1">
              <><div className="back__button me-3" onClick={routeBack}>
                            <IconButton
                                aria-label="ArrowBackIcon"
                                sx={{ backgroundColor: "#FFF"}}
                            >
                                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                            </IconButton>
                        </div>
                        <div className="header--title me-3">
                                <h1 className="title mb-0">
                                    Customer Service Request Details
                                    <i className="bi bi-heart-fill"></i>
                                </h1>
                            </div></>
                            </div>
            )}

  
            <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
              <div className="time__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Request Number
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  #{customerServiceRequest?.requestNumber}
                </Typography>
              </div>

              <div className="time__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Service Type
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  {customerServiceRequest?.serviceName}
                </Typography>
              </div>
              
                <>
                  <div className="company__details me-4 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                    >
                      User
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#333333",
                        fontSize: { xl: "18px", xs: "16px" },
                        fontWeight: 700,
                      }}
                    >
                      {customerServiceRequest?.userName}
                    </Typography>
                  </div>
                  </>
              
  
                  <div className="person__details me-4 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                    >
                      Requested On
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                    >
                      {CommonService.formatDateTime(customerServiceRequest?.createdAt)}
                    </Typography>
                  </div>

               {customerServiceRequest?.status == 'CLOSED' && customerServiceRequest?.timeSpentOnRequest && (<div className="time__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Spent Time
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  {customerServiceRequest?.timeSpentOnRequest} {customerServiceRequest?.timeSpentOnRequest > 1 ?  'Hrs' : 'Hr'} 
                </Typography>
              </div>)}
                
              
            </div>
            </div>
            <div className="header--menu ms-auto mb-xxl-0">
              <div className="header--menu-items">
                <IconButton
                  aria-label="message"
                  size="small"
                  onClick={
                    handleMessageBoxClick
                  }
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF",
                    color: isMessageBoxOpen ? "#FFF" : "#1C1B1F",
                    marginInline: "8px",
                    padding: "8px",
                  }}
                >
                  <MarkunreadOutlinedIcon />
                  {unreadMessageCount > 0  && (
                    <div className="msg-count-wrapper">{unreadMessageCount}</div>
                  )}
                  {showMessageBox && <ArrowDropUpIcon className="arrow-icon" />}
                </IconButton>
                {showMessageBox && (
                  <div className="admin-message-box">
                    <div
                      className="admin-message-box-left-inner"
                      onClick={handleAdminMessageClick}
                    ></div>
                    <div className="admin-message-box-inner positio-relative">
                      <AdminMessageBox
                        selectedGroup={""}
                        requestId={id}
                        requestType={"CUSTOMER_SERVICE_REQUEST"}
                        unreadGroupsCount={unreadGroupsCount}
                        setUnreadGroupsCount={setUnreadGroupsCount}
                        isSeller={false}
                      />
                    </div>
                  </div>
                )}
              


{(customerServiceRequest?.status == 'PENDING_REVIEW' ||  customerServiceRequest?.status == 'UNDER_REVIEW') && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? ["Under Review","Accepted","Rejected"] : ["Cancelled"]}
                />)}

              {customerServiceRequest?.status == 'ACCEPTED'  && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? (customerServiceRequest?.serviceName == 'Ownership Transfer' ? ["In Progress", "Ownership Transferred","Rejected"]: ["In Progress","Rejected"]) : ["Cancelled"]}
                />)}

{customerServiceRequest?.status == 'IN_PROGRESS'  && customerServiceRequest?.serviceName != 'Ownership Transfer' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? ["Rejected","Closed"] : ["Cancelled"]}
                />)}

{customerServiceRequest?.status == 'IN_PROGRESS'  && customerServiceRequest?.serviceName == 'Ownership Transfer' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? ["Rejected","Ownership Transferred"] : ["Cancelled"]}
                />)}

{customerServiceRequest?.status == 'OWNERSHIP_TRANSFERRED' && customerServiceRequest?.serviceName == 'Ownership Transfer' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Rejected","Closed"]}
                />)}

              

                {/* if the status is cancelled or rejected or closed then its a last stage */}
{(customerServiceRequest?.status == 'CANCELLED' || customerServiceRequest?.status == 'REJECTED' || customerServiceRequest?.status == 'CLOSED') && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={true}
                  allowedLabels={[""]}
                />)}
   {customerServiceRequest?.status === "CANCELLED" && (
                <Tooltip
                  title={`Cancellation Reason: ${customerServiceRequest?.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
                {/* {(customerServiceRequest?.status == 'PENDING_REVIEW' && customerServiceRequest?.status == 'OPEN') && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={["Open", "Close"]}
                />)}

                {customerServiceRequest?.status == 'CLOSE' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={["Reopen"]}
                />)} */}
              {!(customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()) && ['PENDING_REVIEW','UNDER_REVIEW'].includes(customerServiceRequest?.status) && (<Tooltip title="Edit" arrow>
                    <IconButton
                        aria-label="message"
                        size="small"
                        disabled= {customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()}
                        onClick={handleEditServiceRequest}
                        sx={{ borderRadius: "50%", backgroundColor: "#FFF", color: "#1C1B1F", marginInline: "8px", padding: "8px" }}
                    >
                         <img className="cursor-pointer" 
                          src={EditIcon}
                          alt="EditIcon"
                        />
                    </IconButton>
                    </Tooltip>)}
                    {!(customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()) && ['PENDING_REVIEW','UNDER_REVIEW'].includes(customerServiceRequest?.status) && ( <Tooltip title="Delete" arrow>
                    <IconButton
                        aria-label="message"
                        size="small"
                        disabled= {customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()}
                        sx={{ borderRadius: "50%", backgroundColor: "#FFF", color: "#1C1B1F", marginInline: "8px", padding: "8px" }}
                        onClick={deleteServiceRequest}
                    >
                        <img src={TrashIcon} />
                    </IconButton>
                    </Tooltip>
                    )}
                
              </div>
            </div>
          </div>
          <div className="viewSampleRequest--wrapper view_request--wrapper view_sample-wrapper viewOrderRequest--wrapper viewServiceRequest--wrapper">
            <div
              className={`${
                isOpenEditSection
                  ? "margin-right-400" // When only `isOpenEditSection` is true
                  : isMessageBoxOpen
                  ? "margin-right-400" // Only `isMessageBoxOpen` is true
                  : "no-margin w-100" // When none of the conditions are met
              }`}
            >
              <div
                className="view-sample-request"
                style={{ zIndex: "10", position: "relative" }}
              >
                
                <div className="row m-0 pt-3">
                  <div className="col-12 px-0">
                        <div className="inventory-file-list-table ">
                        <div className="row m-0">
                    <div className="col-xl-8">
                        <div className="table-responsive service-detail-table-wrapper">

<Table className="service-detail-table">
            <thead>
                <tr>
                    <th className="text-start">Inventory</th>
                    <th className="text-nowrap ">Quantity</th>
                    <th className="text-nowrap ">Barrel No.</th>
                </tr>
            </thead>
            <tbody>
                {customerServiceRequest?.inventoryDetails?.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr style={{
                            borderColor:
                                activeRowIndex === index
                                    ? "transparent"
                                    : "#E4E4DD",
                        }}>
                            <td className="text-start">{`${item?.productName} (${item?.lotId})`}</td>
                            <td className="text-nowrap">{item.quantity}</td>
                            <td className="text-nowrap">
                                <span> {item?.barrelNumbers[0]?.barrelNumber} </span> 
                               {item?.barrelNumbers?.length -1  > 0 &&
                                (<button 
                                    onClick={() => handleRowClick(index)} 
                                    className="barrel-count-btn" 
                                    style={{
                                        borderColor:
                                            activeRowIndex === index
                                                ? "#3A809E"
                                                : "#8F9195",
                                        color:
                                            activeRowIndex === index
                                                ? "#3A809E"
                                                : "#737376"
                                    }}
                                >
                                    {item?.barrelNumbers?.length-1 || 0}
                                   <span>
                                        <img src={ExpandMore} alt="CompanyIcn" width={15} height={8} />
                                    </span>
                                    
                                </button>)}
                            </td>
                        </tr>
                        {activeRowIndex === index && (
                            <tr className="total-barrel-wrapper">
                                <td colSpan={3}>
                                    <Accordion activeKey={activeRowIndex !== null ? "0" : null}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Body>
                                                <div className="total-barrel-list-wrapper">
                                                    <div className="barrel-list">
                                                        {item?.barrelNumbers?.map((barrel, barrelIndex) => (
                                                            <div 
                                                                key={barrelIndex} 
                                                                className="barrel-list-item"
                                                            >
                                                                {barrel?.barrelNumber}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="service-detail-right">
                            <div className="row row-cols-3">
                                {/* itterate customerServiceRequest.dynamicFields */}
                                {customerServiceRequest?.dynamicFields?.map((field, index) => (
                                    <div className="col">
                                    <div className="content-wrapper">
                                        <p className="content-title">
                                        {field?.fieldName}
                                        </p>
                                        <div className="title-bottom-line"></div>
                                        <p className="content-text">{field?.value}</p>
                                    </div>
                                </div>
                            ))}
                                
                                
                            </div>

                                <div>

                                <div className="col-12">
                                    <div className="content-wrapper mt-2">
                                        <p className="content-title">
                                        Comment
                                        </p>
                                        <div className="title-bottom-line"></div>
                                        <p className="content-text">
  {customerServiceRequest?.comment?.trim() ? customerServiceRequest.comment : " - "}
</p>
                                    </div>
                                </div>
                                </div>
                            
                        </div>
                    </div>
                </div>
                      </div>
                </div>
                </div>
              </div>
                <div className="row m-0 p-0">
                  <div className="col-xl-6 mb-3 mb-xl-0 ps-0 pe-0 pe-xl-2">
                    <div className="orderDetail-footer-content-wrapper">
                      <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                        <h3 className="mb-0">List of Documents</h3>
                        <UploadDocs
                          fetchData={fetchData}
                          csvId={id}
                          uploadCSV={false}
                          requestType={"CUSTOMER_SERVICE_REQUEST"}
                        />
                      </div>
                      <div
                        className="inventory-doc-table"
                      >
                        {/* {isLoading ? (
                          <>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              // height={550}
                              style={{ height: "100%" }}
                            />
                          </>
                        ) : ( */}
                          <>
                            <DataGrid
                              localeText={localeText}
                              rows={customerServiceRequestDocs}
                              columns={columnsForDoc}
                              className="data-grid upload-doc-datagrid-table"
                              // hideFooterPagination
                              hideFooter
                              loading={isLoading}
                              disableColumnResize
                            />
                          </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 pe-0 ps-0 ps-xl-2">
                    <div className="recentActivity">
                      <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                        <h3 className="mb-2">Recent Activity</h3>
                      </div>
                      <div className="history-stepper-wrapper">
                        <HistoryStepper historyData={customerServiceRequestHistory} />
                      </div>
                    </div>
                  </div>
                </div>
              
            
          </div>
          {(isOpenEditSection || isMessageBoxOpen) && (
              <div className="msgContainerBox my-3 my-xl-0">
                {isOpenEditSection && (
                  <EditRequest
                    requestType={"SAMPLE_REQUEST"}
                    id={id}
                    refreshData={fetchData}
                    onEditClick={handleEditButtonClick}
                  />
                )}
                {isMessageBoxOpen && (
                  <ContainerMessageBox
                    requestType={"CUSTOMER_SERVICE_REQUEST"}
                    sellerCompanyId={null} 
                    isSeller={false}
                  />
                )}
              </div>
            )}
        </div>
      </div> 
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Customer Service Request Status</Modal.Title>
          {isCancelled && (
            <p className="modal-text">
              Are you sure you want to cancel? {(customerServiceRequest?.status != 'PENDING_REVIEW' && customerServiceRequest?.status != 'UNDER_REVIEW') ? "A cancellation charge may apply." : ""}
            </p>
          )}
          {!isCancelled && (
            <p className="modal-text">
              Close customer service request
            </p>
          )}
        </Modal.Header>
        <Modal.Body>
          {isCancelled && (
            <div className="form__inner">
            <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                rows={8}
                style={{ resize: "none" }}
                value={reason}
                onChange={handleReasonChange}
                placeholder="Enter cancellation reason.."
              />
             
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
          {!isCancelled && (
            <div className="form__inner">

              <label style={{color:" #333333",fontWeight: "700",marginBottom: "6px",}}>Time Spent (hours)<span className="star-required text-danger">*</span></label>
               <Form.Control
                className={`form-control no-spinner ${error ? "is_invalid" : ""}`}
                type="number"
                value={spendHour}
                onChange={handleSpendHourChange}
                placeholder="Enter hours"
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeCustomerServiceRequestStatus}
                disabled={isLoading}
              >
                {isLoading && <Spinner />} {isCancelled ? "Cancel Request" : "Close Request"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      </>
  );
};

export default ViewSupportRequest;